
.Hero {
    min-height: calc(100vh - 150px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

@media screen and (max-width: 992px) {
    .Hero {
        min-height:calc(100vh - 70px);
        margin-bottom: 100px;
    }

    .Hero .Hero_wrraper .Hero_register {
        margin-bottom: 30px
    }
}

.Hero .Hero_wrraper {
    max-width: 1248px;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 1rem
}

.Hero .Hero_wrraper em {
    font-size: larger;
    font-weight: 900;
    text-transform: uppercase;
    color: #ec6090;
}
.Hero .Hero_wrraper p {
    font-size:large;
    font-weight: 400;
    text-transform: lowercase;
    color: black;
}

.Hero .Hero_wrraper .Hero_intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    line-height: 1.4;
    padding: 1rem 0;
    opacity: 0;
    animation: Hero_fade-in__coRyb 1s ease-in-out forwards;
    position: relative
}

.Hero .Hero_wrraper .Hero_intro h1 {
    margin-bottom: 1rem;
    font-size: clamp(1.5rem,6vw,2rem);
    text-transform: capitalize;
   
}
.Hero .Hero_wrraper .Hero_intro p {
    text-transform: capitalize;
    font-size:medium;
    color: #475569 ;
}

.Hero .Hero_wrraper .Hero_intro p {
    margin-top: 20px;
    margin-bottom: 2rem
}

.Hero .Hero_wrraper .Hero_intro a.Hero_register {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: #ec6090;
    padding: 10px 20px;
    border-radius: 8px;
    font-weight: 500;
    color: #292d34;
    transition: all .2s ease-in-out
}

.Hero .Hero_wrraper .Hero_intro a.Hero_register:hover {
    background-color: #ec6091d0
}

.Hero .Hero_wrraper .Hero_ads {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    position: relative
}

.Hero .Hero_wrraper .Hero_ads div.Hero_box {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    width: 250px;
    height: 250px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: -20px 32px 64px rgba(0,0,0,.25);
}

.Hero .Hero_wrraper .Hero_ads div.Hero_box.Hero_bigRectangle__n6NW6 {
    animation: Hero_bigRectangles__zIQ7G .7s ease-in-out forwards;
    width: 340px;
    height: 300px;
    -webkit-animation: Hero_bigRectangles__zIQ7G .7s ease-in-out forwards;
}

.Hero .Hero_wrraper .Hero_ads div.Hero_box.Hero_smallRectangle__FV427 {
    animation: Hero_smallRectangles__g5MJf .7s ease-in-out forwards
}

.Hero .Hero_wrraper .Hero_ads div.Hero_box.Hero_otherRectangle__n5xQL {
    box-shadow: -10px 16px 32px rgba(0,0,0,.1);
    opacity: 0;
    animation: Hero_otherRectangles__HjHOd .7s ease-in-out forwards;
    animation-delay: .3s;
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 40px;
    border-radius: 0
}

.Hero .Hero_wrraper .Hero_ads div.Hero_box img {
    width: 80%
}

.Hero .Hero_animation {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%)
}

@media screen and (max-width: 992px) {
    .Hero .Hero_animation {
        display:none
    }
}

.Hero .Hero_animation .Hero_animation_bg {
    width: 40px;
    height: 70px;
    box-shadow: inset 0 0 0 1px ;
    border-radius: 25px;
    position: relative;
    display: inline-block;
    color : #000 ; 
    bottom: 0
}

.Hero .Hero_animation .Hero_animation_bg:before {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    left: 50%;
    background: #000;
    margin-left: -4px;
    top: 10px;
    border-radius: 4px;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-name: Hero_scroll__r_ftI
}

/* Mobile Tablete */

@media screen and (max-width: 992px) {
    .Hero .Hero_wrraper {
        grid-template-columns:1fr;
        gap: 1rem
    }

    .Hero .Hero_wrraper .Hero_intro {
        align-items: center;
        text-align: center
    }

    .Hero .Hero_wrraper .Hero_ads {
        flex-direction: column;
        gap: 1rem
    }

    .Hero .Hero_wrraper .Hero_ads div.Hero_box.Hero_bigRectangle__n6NW6 {
        width: 280px;
        height: 280px;
        /* z-index: 1; */
    }

    .Hero .Hero_wrraper .Hero_ads div.Hero_box.Hero_bigRectangle__n6NW6.Hero_tod__i3WqF {
        position: absolute;
        margin-left:5px;
        margin-bottom: 250px;
        margin-right: 10px;
        z-index: 1;
        width: 100px;
        height: 100px;
    }

    .Hero .Hero_wrraper .Hero_ads div.Hero_box.Hero_smallRectangle__FV427.Hero_spotify__ThmJR {
        position: absolute;
        margin-left:252px;
        margin-bottom: 230px;
        width: 150px;
        height: 150px;
        z-index: 1;
    }
    .Hero .Hero_wrraper .Hero_ads div.Hero_box.Hero_smallRectangle__FV427.Hero_spotify__ThmJR.hidden {
        display: none;
    }
}

/* ecrant large */

@media (min-width: 600px) {
    .Hero .Hero_wrraper .Hero_ads div.Hero_box.Hero_bigRectangle__n6NW6.Hero_tod__i3WqF {
        position: absolute;
        left: 23%;
        top: 5%;
        z-index: 1;
        width: 100px;
        height: 100px;
    }
    .Hero .Hero_wrraper .Hero_ads div.Hero_box.Hero_smallRectangle__FV427.Hero_spotify__ThmJR {
        position: absolute;
        margin-left:480px ;
        margin-bottom: 250px;
        width: 150px;
        height: 150px;
        z-index: 1;
    }
}

@keyframes Hero_fade-in__coRyb {
    0% {
        opacity: 0;
        transform: translateY(20px)
    }

    to {
        opacity: 1;
        transform: translateY(0)
    }
}

@keyframes Hero_bigRectangles__zIQ7G {
    0% {
        transform: scaleX(.05) scaleY(0) perspective(500px)
    }

    50% {
        transform: scaleX(.05) scaleY(1)
    }

    to {
        transform: scaleX(1) scaleY(1) perspective(500px) rotateY(-15deg) rotateX(8deg) rotate(-1deg)
    }
}

@keyframes Hero_smallRectangles__g5MJf {
    0% {
        transform: scaleX(.05) scaleY(0) perspective(500px)
    }

    50% {
        transform: scaleX(.05) scaleY(1)
    }

    to {
        transform: scaleX(1) scaleY(1) rotate(-5deg)
    }
}

@keyframes Hero_otherRectangles__HjHOd {
    0% {
        transform: rotate(0deg) scale(.6);
        opacity: 0
    }

    50% {
        transform: rotate(1turn) scale(1.2);
        opacity: 1
    }

    to {
        transform: rotate(-30deg) scale(1);
        opacity: .7
    }
}

@keyframes Hero_scroll__r_ftI {
    0% {
        opacity: 1
    }

    to {
        opacity: 0;
        transform: translateY(46px)
    }
}