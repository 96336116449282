@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&family=Archivo+Black&family=Kanit:wght@300&family=Rajdhani:wght@500&family=Sora:wght@500&family=Source+Code+Pro:wght@300&family=Ubuntu&display=swap');

:root {
  --tw-bg-Mybg: #84cc16;
  --tw-bg-MybgHover: #a3e635;
}


body {
  margin: 0;
  font-family: 'Sora', sans-serif;
	-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 22px;
}

code {
  font-family: 'Sora', sans-serif;
}
